import axios from 'axios';
import React, { useState, useEffect, ReactNode } from 'react';
import { Fade, Slide } from "react-awesome-reveal";

interface CustomerFeedbackProps {
    title?: ReactNode;
    subtitle?: ReactNode;
}
interface Feedback {
    id: string;
    name: string;
    position: string;
    profile_of_user: string;
    feedback_text: string;
}
const CustomerFeedback: React.FC<CustomerFeedbackProps> = ({ title, subtitle }) => {
   
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [slides, setSlides] = useState<Feedback[]>([]);

    useEffect(() => {
        if (!isHovered) {
            const timer = setInterval(() => {
                setCurrentSlide((prev) => (prev + 1) % slides.length);
            }, 4000);
            return () => clearInterval(timer);
        }
    }, [slides.length, isHovered]);


    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                const response = await axios.get<Feedback[]>(`${process.env.REACT_APP_BACK_HOST}api/feedbacks`); // Replace with your API endpoint
                setSlides(response.data);
            } catch (error) {
                console.error('Error fetching feedback data:', error);
            }
        };

        fetchFeedback();
    }, []);

    return (
        <div className='flex flex-col justify-center items-center overflow-hidden '>
            <div className=' mx-auto  py-7 md:px-20'>
                <Fade direction='up'>
                    <h2 className='text-3xl font-semibold  bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent'>
                        {title}
                    </h2>
                    <p className='text-xl  '>
                        {subtitle}
                    </p>
                </Fade>

                <div className="overflow-hidden "
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {
                        slides.map((item, index): any => (
                            <>
                                {
                                    index == currentSlide &&

                                    <Slide direction="right" key={index} duration={2000} >
                                        <div className='bg-[#F4F7FF] rounded-2xl flex  justify-center mt-5 border border-gray-200 md:p-10 p-4 '>
                                            <div className=''>
                                                {/* <p className='text-blue-500 text-lg font-semibold text-right mb-6'>Read case study</p> */}
                                                <p className='text-sm md:text-base text-justify mb-6'>{item.feedback_text}</p>
                                                <div className='flex items-center'>
                                                    <img src={`${process.env.REACT_APP_BACK_HOST}${item.profile_of_user}`} alt={item.name} className='w-16 h-16 rounded-full mr-4' />
                                                    <div>
                                                        <p className='font-bold'>{item.name}</p>
                                                        <p className='text-sm text-gray-600'>{item.position}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                }
                            </>
                        ))
                    }
                </div>

                <div className="flex justify-center space-x-4">
                    {slides.map((_, index) => (
                        <>
                            <div className='h-10 flex items-center cursor-pointer'
                                key={index}
                                onClick={() => setCurrentSlide(index)}
                            >
                                <div className={`w-10 h-[2px]  ${index === currentSlide ? 'bg-blue-500' : 'bg-gray-300'}`}
                                />
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomerFeedback;
