import React from 'react';
import { Card, CardContent, CardActions, Grid, Typography, Box, Chip } from '@mui/material';
import { LocationOn, AccessTime } from '@mui/icons-material';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type PositionProps = {
  url: string;
  title: string;
  department: string;
  description: string;
  location: string;
  contractType: string;
  button: { title: string; variant: "contained" | "outlined"; size: "small" | "medium" | "large" };
};

type PositionCardProps = {
  position: PositionProps;
};

type Career23Props = {
  tagline: string;
  heading: string;
  description: string;
  positions: PositionProps[];
};

export const Career23 = () => {
  // The positions are now defined directly in the array
  const positions: PositionProps[] = [
    {
      url: "#",
      title: "Frontend Developer",
      department: "Engineering",
      description:
        "Join our team to build engaging web applications with modern frontend technologies.",
      location: "Pune, Maharashtra",
      contractType: "Full-time",
      button: {
        title: "Apply Now",
        variant: "contained",
        size: "small",
      },
    },
    
    {
      url: "#",
      title: "Backend Developer",
      department: "Engineering",
      description:
        "We are looking for a Backend Developer to design and implement scalable server-side applications.",
      location: "Pune, Maharashtra",
      contractType: "Full-time",
      button: {
        title: "Apply Now",
        variant: "contained",
        size: "small",
      },
    },
    {
      url: "#",
      title: "DevOps Engineer",
      department: "Infrastructure",
      description:
        "Help optimize our deployment pipelines and maintain the cloud infrastructure that powers our apps.",
      location: "Pune, Maharashtra",
      contractType: "Full-time",
      button: {
        title: "Apply Now",
        variant: "contained",
        size: "small",
      },
    },
  ];

  return (
    <section id="career" className='md:px-20 md:py-28 px-4 py-12' >
      <div className="container">
        <div >
          <div
            className="bg-gradient-to-r from-accent to-[#3f0d09] bg-clip-text text-transparent md:text-7xl text-4xl pb-4"            
          >
            Job Openings
          </div>
          <Typography variant="h6" gutterBottom className='pb-8'>
            We know that finding a new job can feel daunting at times, so we try to keep our hiring process as clear and straightforward as possible.
          </Typography>
        </div>
        <Grid style={{ }} container spacing={4}>
          {positions.map((position, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <PositionCard  position={position} />
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

const PositionCard: React.FC<PositionCardProps> = ({ position }) => {
  return (
    <Card variant="outlined" sx={{ padding: '16px', }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" paddingBottom="16px" alignItems="flex-start">
          <a href={position.url}>
            <Typography variant="h6">{position.title}</Typography>
          </a>
          <Chip label={position.department} color="default" size="small" />
        </Box>
        <Typography paddingBottom="16px" variant="body2">
          {position.description}
        </Typography>
        <Box display="flex" flexWrap="wrap" gap="16px">
          <Box display="flex" alignItems="center">
            <LocationOn style={{ marginRight: '8px' }} />
            <Typography variant="body2">{position.location}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <AccessTime style={{ marginRight: '8px' }} />
            <Typography variant="body2">{position.contractType}</Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        {/* <Link
          to="/apply"
          state={{ position }} // Pass position via state
        > */}
          {/* <Button label={position.button.title} circleHover={true} /> */}
          <Button
            label={position.button.title}
            circleHover={true}
            onClick={() => {
              const subject = `Inquiry about ${position.title}`;
              const body = `I am interested in the position: ${position.title}. Please provide more details.`;
              window.location.href = `mailto:info@fladdra.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            }}
          />

        {/* </Link> */}
      </CardActions>
    </Card >
  );
};
