import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import LandingPage from "./pages/landingpage/LandingPage";
import ContactUs from "./pages/contactus/ContactUs";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import About from "./pages/about/about";
import Service from "./pages/service/Services";
import Solutions from "./pages/solutions/Solutions";
import { Toaster } from "react-hot-toast";
import Career from "./pages/career/Career";
import JobApplicationPage from "./pages/career/JobApplicationPage";
import BlogPage from "./pages/blog/BlogPage";
import AdminBlogPage from "./pages/admin/blog/AdminBlogPage";
import AdminLogin from "./pages/admin/login/AdminLogin";
import BlogOverview from "./pages/blog/BlogOverview";
import BlogView from "./pages/admin/blog/BlogView";
import AdminTeamPage from "./pages/admin/team/AdminTeamPage";
import AdminContactPage from "./pages/admin/queries/AdminContactPage";
import TeamUpdate from "./pages/admin/team/TeamUpdate";
import AdminOurWorkPage from "./pages/admin/Work/AdminOurWork";
import WorkUpdate from "./pages/admin/Work/WorkUpdate";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminHome from "./pages/admin";
import SideNav from "./components/sidenavbar";
import AdminFeedbackPage from "./pages/admin/feedback/AdminFeedbackPage";
import FeedbackView from "./pages/admin/feedback/FeedbackView";
import AdminTrustedBrands from "./pages/admin/trustedbrands/AdminTrustedBrands";
import BrandsUpdate from "./pages/admin/trustedbrands/BrandsUpdate";
import TopmateEmbed from "./components/TopmateEmbeded";

const AdminLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div style={{ display: "flex" }}>
            <SideNav />
            <div style={{ flex: 1, padding: "20px" }}>{children}</div>
        </div>
    );
};

// A wrapper to conditionally render NavBar and Footer
const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const location = useLocation();

    // Hide NavBar and Footer for admin routes
    const isAdminRoute = location.pathname.startsWith("/admin");
    const isLoginRoute = location.pathname === "/admin/login";

    if (isLoginRoute) {
        return <>{children}</>;
    }

    return (
        <>
            {!isAdminRoute && <NavBar />}
            {children}
            {!isAdminRoute && <Footer />}
        </>
    );
};

const App: React.FC = () => {

  useEffect(() => {
    (function (
      c: Window & { clarity?: ((...args: any[]) => void) & { q?: any[] } },
      l: Document,
      a: "clarity",
      r: "script",
      i: string
    ) {
      c[a] =
        c[a] ||
        (function (...args: any[]) {
          (c[a]!.q = c[a]!.q || []).push(args);
        } as typeof c["clarity"]);

      const t = l.createElement(r) as HTMLScriptElement;
      t.async = true;
      t.src = "https://www.clarity.ms/tag/" + i;
      const y = l.getElementsByTagName(r)[0];
      y.parentNode!.insertBefore(t, y);
    })(window, document, "clarity", "script", "q9omnwdaga");
  }, []);


      
    return (
        <Router>
            <Layout>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Service />} />
                    <Route path="/solutions" element={<Solutions />} />
                    <Route path="/careers" element={<Career />} />
                    <Route path="/apply" element={<JobApplicationPage />} />
                    <Route path="/Blog" element={<BlogPage />} />
                    <Route path="/blog/:id" element={<BlogOverview />} />

                    {/* Admin Login */}
                    <Route path="/admin/login" element={<AdminLogin />} />

                    {/* Protected Admin Routes */}
                    <Route element={<ProtectedRoute />}>
                        <Route
                            path="/admin/*"
                            element={
                                <SideNav>
                                    <Routes>
                                        <Route path="/" element={<AdminHome />} />
                                        <Route path="blog" element={<AdminBlogPage />} />
                                        <Route path="blogs/:id" element={<BlogView />} />
                                        <Route path="teams/:id" element={<TeamUpdate />} />
                                        <Route path="work" element={<AdminOurWorkPage />} />
                                        <Route path="work/:id" element={<WorkUpdate />} />
                                        <Route path="teams" element={<AdminTeamPage />} />
                                        <Route path="brands" element={<AdminTrustedBrands />} />
                                        <Route path="brands/:id" element={<BrandsUpdate />} />

                                        <Route path="contact" element={<AdminContactPage />} />
                                        <Route path="feedback-admin" element={<AdminFeedbackPage/>} />
                                        <Route path="feedbacks-admin/:id" element={<FeedbackView/>} />
                                    </Routes>
                                </SideNav>
                            }
                        />
                    </Route>
                </Routes>
            </Layout>

            <Toaster
                position="bottom-center"
                toastOptions={{
                    duration: 3000,
                }}
            />
        <TopmateEmbed/>
        </Router>
    );
};

export default App;
