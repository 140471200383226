import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const role = JSON.parse(localStorage.getItem("role") || "null");

        if (token && role === "admin") {
            // Decode token
            const decodedToken = jwtDecode<any>(token);
            const tokenExpiration = decodedToken.exp * 1000;
            
            // Check if the token is expired
            if (tokenExpiration > Date.now()) {
                console.log('evalutating token-------------------------------')
                console.log('evalutating token at protected roputes')
                console.log('-------------------------------')
                // Optionally, validate token on the server
                // You can make an API call here to ensure the token is still valid
                setIsAuthenticated(true);
            } else {
                // Token is expired, clear stored data
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                setIsAuthenticated(false);
            }
        } else {
            setIsAuthenticated(false); // No token or invalid role
        }

        setIsLoading(false);
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // Redirect to login if not authenticated
    if (!isAuthenticated) {
        return <Navigate to="/admin/login" />;
    }

    return <Outlet />; // Render child routes if authenticated
};

export default ProtectedRoute;
