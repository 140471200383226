// PublishedCheckbox.tsx
import { Checkbox } from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
import API from "../../../components/configs/API";

interface PublishedCheckboxProps {
  value: boolean;
  rowId: string | number;
}

const PublishedCheckbox: React.FC<PublishedCheckboxProps> = ({ value, rowId }) => {
  const [checked, setChecked] = useState(value); 

  const handleCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedStatus = e.target.checked;
    setChecked(updatedStatus);  

    try {
      // Prepare the form data
      const formData = new FormData();
      formData.append("is_published", updatedStatus.toString()); 

      const storedUserData = localStorage.getItem("token");    
      await API.put(
        `${process.env.REACT_APP_API_URL}blog_put/${rowId}`,
        formData,  
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: storedUserData, 
          },
        }
      );

      toast.success("Published status updated successfully!");

    } catch (error) {
      console.log("Error updating the blog publish status:", error);
      toast.error("Failed to update published status");
      setChecked(value);
    }
  };

  return (
    <Checkbox
      checked={checked}  
      onChange={handleCheckboxChange}  
      color="primary"
    />
  );
};

export default PublishedCheckbox;
