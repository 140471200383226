// export default API;
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom"; // For redirection after token expiration

// Create an instance of axios
const API = axios.create({
  timeout: 300000, // Set a timeout for API requests
  baseURL: process.env.REACT_APP_API_URL, // Base URL for API requests
  headers: {
    'Content-Type': 'application/json',
  }
});

// Request interceptor to attach the token and check for expiration
API.interceptors.request.use(
  function (config) {
    const storedUserData = localStorage.getItem('token');
    if (storedUserData) {
      const token = JSON.parse(storedUserData);

      // Decode the token to check its expiration
      try {
        const decodedToken: any = jwtDecode(token);

        // Check if the token has expired
        if (decodedToken.exp * 1000 < Date.now()) {
          // If token is expired, remove it from localStorage and redirect to login
          localStorage.removeItem('token');
          localStorage.removeItem('role'); // Remove any other related data
          console.log('token is expired -----------------------------')
          // Optional: You can use navigate to redirect to login here if this is inside a React component
          // Since interceptors don't have access to `useNavigate`, you would need another approach for that
          window.location.href = "/admin/login"; // Or use your routing library to navigate

          // Return a rejected promise so no API request is made
          return Promise.reject(new Error('Token expired'));
        }

        // Attach the valid token to the request headers
        if (config.headers) {
          // console.log('##########authorized user token')
          config.headers["Authorization"] = token;
        }
      } catch (error) {
        // In case the token is invalid or malformed, remove it from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('role');

        // Redirect to login or handle the error gracefully
        window.location.href = "/admin/login"; // You can use your routing library for redirect

        return Promise.reject(new Error('Invalid token'));
      }
    }
    return config; // Continue with the request if no issues
  },
  function (error) {
    return Promise.reject(error); // Reject request if error occurs
  }
);

export default API;


// import axios from "axios";
// const storedUserData = JSON.parse(localStorage.getItem('token') as any);
// // const token = JSON.parse(storedUserData);

// const API = axios.create({timeout: 300000,
//   baseURL: process.env.REACT_APP_API_URL, 
//   headers: {
//     'Content-Type': 'application/json',
//     Authorization: `${storedUserData}`
//   }
// });

// API.interceptors.request.use(
//   function (config) {
//     const storedUserData = localStorage.getItem('token');
//     if (storedUserData){
//       console.log('insideeeeeeeeee')
//       console.log('storedUserDatastoredUserData', storedUserData)
//       const token = JSON.parse(storedUserData);
//       console.log('inside api',token);
//       if (token) {
//         if (config.headers) {
//           config.headers["Authorization"] = token;
//         }
//       }
//     }
//     return config;
//   },
//   function (error) {
//       return Promise.reject(error);
//   }
// );

// export const serverAPI = axios.create({
//   baseURL: process.env.NEXT_PUBLIC_SERVER_API_URL,
// });
