import React from "react";
import { AppBar as MuiAppBar, Box, Toolbar, IconButton, Typography, Avatar, Menu, MenuItem } from "@mui/material";
import { MdMenu, MdLogout } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";

const AppBar = ({ handleDrawerToggle }: any) => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Handle menu open and close
  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleLogout = () => {
  //   navigate("/admin/login");
  // };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    navigate("/admin/login");
  };

  // Function to get the current tab name from the URL
  const getTabName = (path: string) => {
    // Simple logic to map the path to a readable tab name
    switch (path) {
      case '/admin':
        return 'Dashboard';
      case '/admin/blog':
        return 'Blog';
      case '/admin/users':
        return 'Users';
      case '/admin/settings':
        return 'Settings';
      default:
        return 'Admin'; // Default tab name if no match is found
    }
  };

  const currentTab = getTabName(location.pathname); // Get current tab name based on the path

  return (
    <MuiAppBar position="fixed" style={{ backgroundColor: "#f9fafc", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
      <Toolbar>
        {/* Mobile Menu Icon */}
        <IconButton edge="start" color="inherit" onClick={handleDrawerToggle} style={{ marginRight: "16px" }}>
          <MdMenu className="text-accent bg-#f9fafc" />
        </IconButton>

        {/* Back Navigation */}
        <div className="text-accent lg:ml-56">
          <IconButton color="inherit" onClick={() => navigate(-1)}>
            <IoIosArrowBack />
          </IconButton>
        </div>

        {/* Current Tab Name */}
        <Typography variant="h6" color="black" style={{ marginLeft: "16px" }}>
          {currentTab} {/* Display the current tab name */}
        </Typography>

        {/* User Avatar Dropdown */}
        <Box style={{ marginLeft: "auto" }}>
          <IconButton onClick={handleMenuOpen}>
            <Avatar style={{ backgroundColor: "#275ed3" }}>U</Avatar>
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>
              <MdLogout style={{ marginRight: "8px" }} /> Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
