import React from 'react';

interface ContactCardProps {
  email?: string;
  phone?: string;
  scheduleMeetingLink?: string;
}

const ContactCard: React.FC<ContactCardProps> = ({ email, phone, scheduleMeetingLink }) => {
  return (
    <div className="bg-white shadow-lg rounded-3xl border border-blue-800 p-6 md:mx-auto md:w-[80%] ">
      {/* Card Title */}
      <h3 className="text-2xl font-semibold textcenter flex mb-4">Connect with Us</h3>

      {/* Card Content */}
      <div className="space-y-4">
        {/* Email Section */}
        <div className=" ">
          <p className="text-lg text-gray-600">Share a detailed specification</p>
          <a href={`mailto:${email}`} className="text-blue-500 hover:text-blue-700">
            {email}
          </a>
        </div>

        {/* Phone Section */}
        <div className="">
          <p className="text-lg text-gray-600">Connect on phone</p>
          <a href={`tel:${phone}`} className="text-blue-500 hover:text-blue-700">
            {phone}
          </a>
        </div>

        {/* Schedule a Meeting Section */}
        {/* <div className="flex items-center justify-between">
          <p className="text-lg text-gray-600">Schedule a Meeting</p>
          <a
            href={scheduleMeetingLink}
            className="inline-block px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
          >
            Book Now
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default ContactCard;
